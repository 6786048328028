.single-product__image{
    width: 17vw;
}

@media screen and (max-width: 600px) {
    .single-product__image{
        width: 38vw;
    }
  }
  

  @media screen and (max-width: 800px) {
    .single-product__image{
        width: 40vw;
    }
  }
  
  @media screen and (max-width: 1000px) {
    .single-product__image{
        width: 30vw;
    }
  }
  