.carousel-caption {
    position: absolute;
    top: 20%;
    height: 50%;
}

.carousel-container {
    position: relative;
    width: 100vw;
    height: 85vh;
    overflow: hidden;
  }
  
  .carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  }

  @media screen and (max-width:400px) {    
    .carousel-caption{
      top: 50%;
    }
    h3{
      font-size: 1rem !important;
    }

    p{
        font-size: 0.5rem !important;
    }
  }

  @media screen and (max-width: 800px) {
    .carousel-caption{
      top: 20%;
    }
    h3{
      font-size: 2.3rem !important;
    }
    p{
      font-size: 1rem !important;
  }
  }

  @media screen and (max-width: 1200px) {
    h3{
      font-size: 3rem !important;
    }
    p{
      font-size: 1.5rem !important;
  }
  }