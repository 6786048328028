a {
  transition: all 0.2s ease;
}

a:hover {
  color: black !important;
  font-weight: bold;
}

.footer__icon {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 5rem;
}
