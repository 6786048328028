nav{
    background-color: #ffbf4a !important;
}

.branding_button{
    border-radius: 10rem;
    background-color: black;
    color: white;
    transition: all 0.2s ease;
    height: 3.5rem;
    letter-spacing: 0.15rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.branding_button:hover{
    background-color: white;
    color: black;
}

a{
    transition: all 0.2s ease;
}

a:hover{
    color: black !important;
    font-weight: bold;
}

.navbar__logo{
    height: 70px;
    padding: 0;
}

@media screen and (max-width: 600px) {
    .navbar__logo{
        height: 40px;
        padding: 0;
    }
}