.hero__heading{
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-20%,-50%);
    color: white;
    font-weight: bold;
    font-size: 03.5rem;
}

@media screen and (max-width: 600px) {
    section{
        padding: 0rem 1rem;
    }

    p{
        font-size: 1rem !important;
    }
}