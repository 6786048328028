.branding_image {
  width: 40vh;
}

.equal-height {
  display: flex;
  flex-wrap: wrap;
}

.equal-height > .col-md-5 {
  display: flex;
  flex-direction: column;
}

.equal-height > .col-md-5 > * {
  flex: 1;
}

.load_more_btn{
    background-color: #ac8949;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    transition: all 0.2s ease;
    border: 1px solid transparent;
}

.load_more_btn:hover{
    color: #ac8949;
    background-color: white;
    border: 1px solid #ac8949;
}


.linkedin__icon {
  position: fixed;
  bottom: 25%;
  right: 5%;
  color: #0A66C2;
  padding: 0.8rem;
  background-color: black;
  border-radius: 50%;
  transition: all 0.2s ease;
  cursor: pointer;
  z-index: 10;
}

.linkedin__icon:hover {
  transform: scale(1.15);
}

.linkedin__icon p {
  position: absolute;
  top: 13%;
  right: 20%;
  color: white;
  border-radius: 50%;
  font-size: 1rem;
}

.whatsapp__icon {
  position: fixed;
  bottom: 5%;
  right: 5%;
  color: #25D366;
  padding: 0.5rem;
  background-color: black;
  border-radius: 50%;
  transition: all 0.2s ease;
  cursor: pointer;
  z-index: 10;
}

.whatsapp__icon:hover {
  transform: scale(1.15);
}

.whatsapp__icon p {
  position: absolute;
  top: 13%;
  right: 20%;
  color: white;
  border-radius: 50%;
  font-size: 1rem;
}

.call__icon {
  position: fixed;
  bottom: 15%;
  right: 5%;
  color: white;
  padding: 0.8rem;
  background-color: black;
  border-radius: 50%;
  transition: all 0.2s ease;
  cursor: pointer;
  z-index: 10;
}

.call__icon:hover {
  transform: scale(1.15);
}

.call__icon p {
  position: absolute;
  top: 13%;
  right: 20%;
  color: white;
  border-radius: 50%;
  font-size: 1rem;
}
