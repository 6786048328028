.contact-hero__heading {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-20%, -50%);
  color: #2596be;
  font-weight: bold;
  font-size: 03.5rem;
}

.contact__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
}

.contact__item a:hover {
  font-weight: normal;
}

@media screen and (max-width: 600px) {
  .contact__item {
    margin-bottom: 1rem;
  }

  .contact__container{
    margin-bottom: 2rem;
  }
}
