.single__product {
    background-color: white;
    padding: 3rem 1rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease;
    color: black;
    border: 0.25rem solid #ac8949;
  }

  .equal-height {
    display: flex;
    flex-wrap: wrap;
  }
  
  .equal-height > .col-md-5 {
    display: flex;
    flex-direction: column;
  }
  
  .equal-height > .col-md-5 > * {
    flex: 1;
  }


  @media screen and (max-width: 600px) {
    .single__product {
      margin-bottom: 1rem;
    }
  }
  

  @media screen and (max-width: 1000px) {
    #products {
      padding: 1rem;
    }

    .single__product {
      margin-bottom: 2rem;
    }
  }

  @media screen and (min-width: 1000px) {
    .single__product {
      margin-bottom: 2rem !important;
    }
  }