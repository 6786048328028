.product_container {
  background-color: white;
  padding: 3rem 1rem;
  margin: 1rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  color: black;
  border: 0.25rem solid #ac8949;
  cursor: pointer;
}

.product_container:hover {
  background-color: #ac8949;
  color: white;
  scale: 1.05;
}
